import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const IsItPossibleToCreateAScratchCampaign = ({ location }) => {
  const title = "即时赢取结果的设计可以改变吗？"
  const list = [
    { title: "常见问题", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="通过使获胜结果更像游戏，如老虎机或刮刮乐，可以使活动更加吸引人。 这可以使参与者比普通活动中更强烈地意识到品牌。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="常见问题" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              是否可以自定义显示中奖结果的屏幕的表现形式？
            </h1>
            <p className="qa-page__answer">
              是的，这是一种选择，但我们可以提供这种服务。
              <br />
            </p>
            <p className="qa-page__text">
              可以用符合品牌形象的图像和人物来展示制作，并以产品或服务的名称来打动参与者。
            </p>
            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">
                  从参与活动到收到产品的过程中，方向是定制的。
                </h2>

                <div className="qa-page__text">
                  <ol className="qa-page__ol">
                    <li>通过转发微博加入活动（其他参与方式也可以）</li>
                    <li>通过直接信息通知结果</li>
                    <li>竞选网站的直接信息网址</li>
                    <li>
                      在网站上显示分期付款（视频、动画、游戏、幻灯片等...）
                    </li>
                    <li>表演结束后，会显示赢或输的结果</li>
                    <li>如果你赢了，可以获得奖品</li>
                  </ol>
                </div>

                <h3 className="qa-page__h3">
                  请随时与我们联系，以设计和建议定制分期付款区。
                </h3>

                <p className="qa-page__text">
                  也可以设计活动，例如，如果人们在结果显示后回答问卷，可以让他们参与双重机会。
                </p>

                <p className="qa-page__text">
                  请联系我们，了解更多关于定制舞台的信息，因为这是PARKLoT的可选功能。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default IsItPossibleToCreateAScratchCampaign
